<template>
    <div class="w-100 h-100 position-relative">
        <div v-if="enabled" class="d-flex">
            <vs-input
                v-if="isOpenInput"
                v-model="keyword"
                border
                shadow
                class="w-100 mw-100"
                :label="`Nueva ${label} (Separa por ',')`"
                :state="stateInputDark"
                @keyup.enter="addNewKeyword"
            />
            <vs-select
                v-else
                v-model="keywords"
                :label="label"
                filter
                multiple
                :state="stateInputDark"
                collapse-chips
                class="w-100 mw-100 border-custom"
                @input="onInput"
            >
                <vs-option
                    v-for="(item, index) in keywordsBase"
                    :key="index"
                    :label="item"
                    :value="item"
                >
                    {{ item }}
                </vs-option>
            </vs-select>

            <vs-button icon border @click="changeActionKeys">
                {{ isOpenInput ? "✔️" : "➕" }}
            </vs-button>
        </div>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> {{ label }} </label>
            </div>

            <div class="d-flex flex-wrap px-2">
                <div
                    v-for="(item, index) in keywords"
                    :key="index"
                    class="bg-primary rounded mx-1 mt-1 px-1"
                    :label="item"
                    :value="item"
                >
                    <small>{{ item }}</small>
                </div>
                <div v-if="keywords.length === 0" class="">Sin {{ label }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "KeywordsSelector",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        label: {
            type: String,
            default: () => "",
            required: true
        },
        enabled: {
            default: () => false,
            type: Boolean,
            required: false
        },
        base: {
            type: Array,
            default: () => [],
            required: false
        },
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    emits: ["change"],
    data: () => ({
        keywords: [],
        isOpenInput: false,
        keyword: "",
        keywordsBase: []
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },

    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        setInput(value) {
            this.keywordsBase = [...this.base, ...value].filter(
                (value, index, self) =>
                    self.findIndex((item) => item === value) === index
            );
            this.keywords = [...value];
            this.isOpenInput = !this.keywords.length;
        },
        addNewKeyword() {
            if (this.keyword) {
                const keywords = this.keyword.split(", ");
                this.keywordsBase = [...keywords, ...this.keywordsBase];
                this.keywords = [...keywords, ...this.keywords];
                this.keyword = "";
                this.onInput();
            }
            this.isOpenInput = false;
        },
        changeActionKeys() {
            if (this.isOpenInput) {
                this.addNewKeyword();
            } else {
                this.isOpenInput = true;
            }
        },
        onInput() {
            this.$emit("change", this.keywords);
        }
    }
};
</script>
