<template>
    <div class="w-100">
        <div class="d-flex flex-wrap">
            <div
                v-for="(item, index) in itemsCurrent"
                :key="item.id"
                class="ml-4 mt-4 position-relative"
            >
                <img
                    loading="lazy"
                    :src="
                        item.uploaded
                            ? item.url.replace('large', 'normal')
                            : item.url
                    "
                    :alt="item.alt"
                    width="120"
                    class="rounded border border-secondary"
                />
                <div class="position-absolute -bottom-1 -left-1 d-flex">
                    <button
                        v-if="enabled"
                        class="btn btn-danger len-3 d-flex align-items-center justify-content-center"
                        @click="deleteImage(index)"
                    >
                        🗑️
                    </button>
                </div>
                <div class="position-absolute -top-2 -right-2 d-flex">
                    <button
                        v-if="enabled"
                        class="btn len-3 d-flex align-items-center justify-content-center"
                        :disabled="index === 0"
                        @click="moveOrder(index, index - 1)"
                    >
                        ⬅️
                    </button>
                    <div
                        class="bg-info len-3 rounded-circle d-flex align-items-center justify-content-center"
                    >
                        <small> {{ item.id }} </small>
                    </div>
                    <button
                        v-if="enabled"
                        class="btn len-3 d-flex align-items-center justify-content-center"
                        :disabled="index >= itemsCurrent.length - 1"
                        @click="moveOrder(index, index + 1)"
                    >
                        ➡️
                    </button>
                </div>
            </div>
            <div class="mt-4">
                <vs-tooltip v-if="enabled && $enabledEcommerceOptions" border>
                    <button
                        class="ml-4 mt-2 border border-secondary btn rounded len-120px d-flex justify-content-center align-items-center"
                        @click="openNewImage"
                    >
                        <span class="h2">➕</span>
                    </button>
                    <template #tooltip> Agregar Imágenes</template>
                </vs-tooltip>
            </div>
        </div>
        <div>
            <vs-dialog
                v-model="selectImage"
                width="80vw"
                scroll
                :loading="isLoading"
                :prevent-close="isLoading"
            >
                <template #header>
                    <div class="mt-2">
                        <h3 v-show="!isOpenImageCreator">
                            Seleccione las imágenes para {{ message }}
                            {{ id }} 📷
                        </h3>
                        <h3 v-show="isOpenImageCreator">Crear nueva imagen</h3>
                    </div>

                    <div class="d-flex">
                        <vs-button
                            v-show="
                                !isOpenImageCreator && selectedImages.length
                            "
                            icon
                            circle
                            @click="pushNewImage"
                        >
                            ✔️
                        </vs-button>
                        <vs-tooltip
                            v-if="$ability.hasScope('image:Create')"
                            border
                            bottom
                        >
                            <vs-button
                                circle
                                icon
                                floating
                                @click="
                                    isOpenImageCreator = !isOpenImageCreator
                                "
                            >
                                {{ isOpenImageCreator ? "🔙" : "➕" }}
                            </vs-button>
                            <template #tooltip>
                                <span>
                                    {{
                                        isOpenImageCreator
                                            ? "Lista de imágenes"
                                            : "Cargar nueva imagen"
                                    }}
                                </span>
                            </template>
                        </vs-tooltip>
                    </div>
                </template>
                <div class="con-content">
                    <div v-if="isOpenImageCreator">
                        <image-creator
                            @loading="onLoading"
                            @created="selectionUploadImage"
                        />
                    </div>
                    <div v-else>
                        <div
                            class="d-flex justify-content-between flex-column flex-lg-row col-12"
                        >
                            <div
                                class="my-3 d-flex align-items-center rounded-circle justify-content-around"
                            >
                                <vs-input
                                    v-model="keyword"
                                    type="text"
                                    border
                                    placeholder="Buscar .."
                                    class="mr-2"
                                    @click-icon="advanceSearch"
                                    @keyup.enter="advanceSearch"
                                >
                                    <template #icon> 🔎 </template>
                                </vs-input>
                                <vs-button
                                    circle
                                    icon
                                    floating
                                    @click="reloadItems"
                                >
                                    <reload-icon />
                                </vs-button>
                            </div>

                            <vs-pagination
                                v-model="currentPage"
                                :length="pagesNumber"
                                @input="listItems()"
                            />
                        </div>
                        <div
                            v-if="imagesList.length"
                            class="w-100 d-flex flex-wrap"
                        >
                            <div
                                v-for="(item, index) in imagesList"
                                :key="index"
                                class="col-6 col-md-4 col-lg-3 col-xl-4 mt-3"
                            >
                                <button
                                    class="btn rounded p-0"
                                    :class="
                                        selectedImages.includes(item.id)
                                            ? `${borderCardDark} border-4`
                                            : 'border'
                                    "
                                    @click="selectionImage(item.id)"
                                >
                                    <img
                                        class="w-100"
                                        loading="lazy"
                                        :src="item.url | image"
                                        :alt="item.alt"
                                    />
                                    <div
                                        class="max-w-24 position-absolute bg-info d-flex align-items-center rounded text-white container__date max-w-24 pl-2 py-2"
                                    >
                                        <div class="d-flex flex-column">
                                            <small>
                                                {{ item.alt ? item.alt : "NA" }}
                                            </small>
                                        </div>
                                        <a
                                            :href="`/app/images/detail/${item.id}`"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            🖊️
                                        </a>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div v-else class="col-11 mx-auto mt-4">
                            <strong>
                                Nuestro sistema NO encuentra resultados. Intenta
                                modificar los filtros de búsqueda.
                            </strong>
                        </div>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import ImageCreator from "@/components/utils/ImageCreator.vue";

export default {
    name: "ImagesManage",
    components: {
        ImageCreator,
        ReloadIcon
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [
                {
                    alt: "alt",
                    id: 1,
                    order: { order: 0 },
                    url: ""
                }
            ],
            required: true
        },
        id: {
            type: String,
            default: () => "0",
            required: true
        },
        message: {
            type: String,
            default: () => "reference",
            required: false
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        selectImage: false,
        selectedImages: [],
        isLoading: false,
        currentPage: 1,
        pagesNumber: 0,
        isOpenImageCreator: false,
        imagesList: [],
        showTable: false,
        itemsNumber: 0,
        backup: {
            items: [],
            pagination: { pagesNumber: 1, itemsNumber: 1 }
        },
        keyword: ""
    }),
    computed: {
        ...mapState("images", ["images"]),
        ...mapGetters("control", ["backgroundColor", "borderCardDark"])
    },
    watch: {
        keyword(value) {
            if (value) {
                this.imagesList = this.images.filter((item) =>
                    item.alt.includes(value.toLowerCase())
                );
                this.itemsNumber = this.imagesList.length;
                this.pagesNumber = 1;
                this.currentPage = 1;
            } else {
                this.imagesList = [...this.backup.items];
                this.itemsNumber = Number(this.backup.pagination.itemsNumber);
                this.pagesNumber = Number(this.backup.pagination.pagesNumber);
            }
        }
    },
    mounted() {},
    methods: {
        ...mapActions("images", ["listImages"]),
        async openNewImage() {
            this.selectImage = true;
            this.listItems(true);
        },
        async reloadItems() {
            this.keyword = "";
            this.currentPage = 1;
            await this.listItems(true);
        },
        async advanceSearch() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        async listItems(complete = false) {
            this.isLoading = true;
            try {
                const { items, pagination } = await this.listImages({
                    page: this.currentPage,
                    keyword: this.keyword,
                    complete
                });
                this.imagesList = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.backup = {
                    items,
                    pagination: {
                        itemsNumber: Number(this.itemsNumber),
                        pagesNumber: Number(this.pagesNumber)
                    }
                };
                this.showTable = true;
            } catch (error) {
                this.selectImage = false;
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        moveOrder(fromIndex, toIndex) {
            let arrAux = [...this.itemsCurrent];
            const aux = arrAux[fromIndex];
            arrAux.splice(fromIndex, 1);
            arrAux.splice(toIndex, 0, aux);
            const imagesResult = arrAux.map((item, index) => ({
                ...item,
                order: { order: index }
            }));
            this.$emit("change", imagesResult);
        },
        selectionImage(id) {
            let fromIndex = this.selectedImages.findIndex(
                (item) => item === id
            );
            if (fromIndex === -1) {
                this.selectedImages.push(id);
            } else {
                this.selectedImages.splice(fromIndex, 1);
                let arrAux = [...this.itemsCurrent];
                fromIndex = arrAux.findIndex((item) => item === id);
                if (fromIndex !== -1) {
                    arrAux.splice(fromIndex, 1);
                    this.$emit("change", arrAux);
                }
            }
        },
        pushNewImage() {
            let imagesAux = this.imagesList.filter((i) =>
                this.selectedImages.includes(i.id)
            );
            if (imagesAux.length) {
                const imagesResult = [...this.itemsCurrent];

                imagesAux = imagesAux.map((item, index) => ({
                    ...item,
                    order: { order: this.itemsCurrent.length + index }
                }));
                imagesAux = [...imagesResult, ...imagesAux];

                imagesAux = imagesAux.filter(
                    (value, index, self) =>
                        self.findIndex((item) => item.id === value.id) === index
                );
                this.selectImage = false;
                this.$emit("change", imagesAux);
            }
        },
        deleteImage(fromIndex) {
            let imagesResult = [...this.itemsCurrent];
            imagesResult.splice(fromIndex, 1);
            this.$emit("change", imagesResult);
        },
        selectionUploadImage(data) {
            this.imagesList.unshift({ ...data, uploaded: false });
            this.selectedImages.unshift(data.id);
            this.isOpenImageCreator = false;
        },
        onLoading(value) {
            this.isLoading = value;
        }
    }
};
</script>
